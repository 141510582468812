<template>
    <v-list two-line>
        <v-divider></v-divider>
        <template v-for="(vd1, index) in filteredOrders">
            <v-list-item :key="vd1.vd1pedido" link @click="showOrder(vd1)">
                <v-list-item-content>
                    <v-list-item-title class="text-h6 text-left">
                        {{ vd1.vd1pedido }}
                    </v-list-item-title>

                    <v-list-item-subtitle
                        class="text--grey caption text-left"
                    >{{ vd1.orderDate }}</v-list-item-subtitle>

                    <v-list-item-subtitle
                        class="text-left"
                        v-text="vd1.vd1statdes"
                    ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="align-self-end mb-0">
                    <v-list-item-action-text class="body-2 font-weight-bold" :style="{color: parameters.primaryColor}">
                        {{ vd1.vd1total | money }}
                    </v-list-item-action-text>
                </v-list-item-action>
            </v-list-item>

            <v-divider
                v-if="index < filteredOrders.length - 1"
                :key="index"
            ></v-divider>
        </template>
    </v-list>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        order: {
            get() {
                return this.$store.state.order;
            },
        },
        ...mapGetters({
            filteredOrders: 'order/filteredOrders',
        }),
    },
    methods:{
        showOrder(vd1){
            this.$router.push(`pedido/${vd1.vd1pedido}`)
        }
    }
};
</script>

<style>
</style>