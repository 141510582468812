<template>
    <v-data-table
        :headers="headers"
        :items="filteredOrders"
        no-data-text="Você não fez nenhum pedido ainda"
        no-results-text="Nenhum pedido encontrado"
        :footer-props="{itemsPerPageText: 'Linhas por página'}"
        locale="pt-BR"
    >
        <template v-slot:item="{ item: vd1 }">
            <tr>
                <td class="text-left">{{ vd1.vd1pedido }}</td>
                <td class="text-left">{{ vd1.orderDate }}</td>
                <td class="text-left">{{ vd1.vd1statdes }}</td>
                <td class="text-left">{{ vd1.vd1total | money }}</td>
                <td class="text-left">{{ vd1.qtdeItems }}</td>
                <td class="text-left" v-if="new Date(vd1.vd1entrega) > new Date('1900-01-01')">{{ vd1.vd1entrega | shortDate }}</td>
                <td class="text-left" v-else>Sem previsão</td>
                <td>
                    <v-icon
                        small
                        @click="showOrder(vd1)"
                    >
                        mdi-open-in-new
                    </v-icon>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        headers: [
            { text: 'Pedido', value: 'vd1pedido' },
            { text: 'Data pedido', value: 'vd1abert' },
            { text: 'Status', value: 'vd1statdes' },
            { text: 'Total', value: 'vd1total' },
            { text: 'Qtde produtos', value: 'qtdeItems' },
            { text: 'Data entrega', value: 'vd1entrega' },
            { text: '', value: '' },
        ],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        order: {
            get() {
                return this.$store.state.order;
            },
        },
        ...mapGetters({
            filteredOrders: 'order/filteredOrders',
        }),
    },
    methods:{
        showOrder(vd1){
            this.$router.push(`pedido/${vd1.vd1pedido}`)
        }
    }
};
</script>

<style>
</style>