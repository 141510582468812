<template>
    <v-main style="min-height: 80% !important;" >
        <v-container class="pb-12">
            <v-row>
                <v-col cols="12" class="py-0">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class="text-left mb-3"
                        :class="$mq != 'lg' ? 'mt-3': 'mt-6'"
                    >
                        Meus Pedidos
                    </h2>
                </v-col>
                <v-col :cols="$mq != 'lg' ? 12: 6">
                    <v-text-field
                        v-model="order.search"
                        append-icon="mdi-magnify"
                        label="Pedidos, data de pedido, produtos e mais"
                        placeholder="Ex.: 00011; 27/09/2021; Camisa amarela...."
                        single-line
                        outlined
                        hide-details
                        dense
                        :color="parameters.secondaryColor"
                    ></v-text-field>
                </v-col>
                <v-col :cols="$mq != 'lg' ? 12: 6">
                    <v-select
                        v-model="order.select"
                        :items="lstStatus"
                        label="Status do pedido"
                        multiple
                        outlined
                        dense
                        item-text="text"
                        item-value="value"
                        hide-details
                        :color="parameters.secondaryColor"
                        :item-color="parameters.secondaryColor"
                    ></v-select>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <OrderListMobile v-if="$mq != 'lg'"/>
                    <OrderListDesktop v-else/>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const OrderListMobile = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/order/OrderListMobile.vue"
    );
const OrderListDesktop = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/order/OrderListDesktop.vue"
    );

export default {
    data: () => ({
        lstStatus: [
            { text: "Pendente", value: "001" },
            { text: "Em produção", value: "002" },
            { text: "Em transporte", value: "003" },
            { text: "Entregue", value: "004" },
            { text: "Cancelado", value: "005" },
        ],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        order: {
            get() {
                return this.$store.state.order;
            },
        },
    },
    components:{
        OrderListDesktop,
        OrderListMobile
    }
};
</script>

<style>
</style>